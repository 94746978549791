import { useState, useEffect, memo } from 'react';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import { AutocompleteOption, Avatar, Box, Button, Image, ListItemContent, ListItemDecorator, Typography } from "@mui/joy";
import Input from '@mui/joy/Input';

import SearchIconSvg from '../assets/search.svg'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


export default function SearchBarModal({ open, setOpen }) {

    function SearchIcon() {
        return <img src={SearchIconSvg} style={{opacity: 0.2}} />
    }

    const [data, setData] = useState([])
    const [inputValue, setInputValue] = useState('');

    // function fetchData(newVal) {
    //     // setIsLoading(true);
    //     fetch('https://catdog-api.vercel.app/api/search?q=' + (newVal || '')).then(r => r.json()).then(r => {
    //         setData(r)
    //         // setIsLoading(false);
    //     });
    // }

    // useEffect(() => {
    //     fetchData()
    // }, [])


    // useEffect(() => {
    //     if (inputValue.length > 1) {
    //         fetchData(inputValue);
    //     }
    // }, []);



    // function SearchBarInput() {
    //     return       <Autocomplete
    //     color='success'
    //     startDecorator={<SearchIcon />}
        // endDecorator={<CloseRoundedIcon sx={{background: '#ffba00', color: 'white', height: '100%', width: '42px', padding: 1, borderRadius: '10px', zIndex: 5, ":hover": {
        //     cursor: 'pointer'
        // }, position: 'absolute', right: 0 }} onClick={() => setOpen(false)}/>}
    //     placeholder="Search coins"
    //     size='lg'
    //     options={data}
    //     open
    //     class='container'
    //     isOptionEqualToValue={(option, value) => option.name.toLowerCase().includes(value.toLowerCase())}
    //     sx={{
        //   width: '50%',
        //   minWidth: '300px',
    //       background: '#1F2634',
    //       boxShadow: 'none',
    //       borderRadius: '10px',
    //       borderColor: '#ffba00',
    //       paddingRight: 0,
    //       "& input::placeholder": {
    //         fontWeight: 300,
    //         opacity: 0.2
    //       },
    //     }}
    //     getOptionLabel={(option) => {
    //       return option.name || inputValue
    //     }}
    //     // value={inputValue}
    //     onInputChange={(e, newVal) => {
    //       // fetchData(newVal)
    //       setInputValue(newVal)
    //     }}        
    //     // disabled={isLoading}
    //     renderOption={(props, option) => (
        //   <Box sx={{display: 'flex', padding: 1, borderRadius: '10px', margin: 1, background: '#0e172b', border: '1px #3c4456 solid', ":hover": {
        //     cursor: 'pointer'
        //   }}} key={option.token} onClick={() => window.open('/coins/' + option.token, '_self')}>
        //     <ListItemDecorator sx={{ marginRight: '10px' }}>
        //       <img
        //         loading="lazy"
        //         width="40"
        //         src={option.icon}
        //         alt=""
        //       />
        //     </ListItemDecorator>
        //     <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        //       <ListItemContent>
        //         <Typography style={{ color: 'white' }} level="title-sm">{option.name}</Typography>
        //         <Typography style={{ color: 'white', opacity: 0.6 }} level="body-sm">{option.token.slice(0, 4)}...{option.token.slice(-4)}</Typography>
        //       </ListItemContent>
        //       <ListItemContent>
        //       </ListItemContent>
        //     </div>
        //   </Box>
    //     )}
        // slotProps={{
        //   listbox: {
        //     sx: (theme) => ({
        //       background: 'transparent',
        //       border: 'none',
        //       boxShadow: 'none'
        //     })
        //   }
        // }}
    //   />
    // }

    useEffect(() => {
        fetch('https://catdog-api.vercel.app/api/search?q=' + inputValue).then(r => r.json()).then((data) => setData(data));
      }, [inputValue]);

    const SearchBarInput = memo(() => {

        return (
            <div class='w-full container flex items-center flex-col gap-4 h-full'>
                <Input color='success' placeholder='Search coins' size='lg'  sx={{
                    background: '#333333' || '#1F2634',
                    boxShadow: 'none',
                    borderRadius: '10px',
                    width: '50%',
                    minWidth: '300px',
                    borderColor: '#f4b55b',
                    ":focus": {
                        borderColor: '#f4b55b'
                    },
                    "& input::placeholder": {
                        fontWeight: 300,
                        opacity: 0.2,
                        color: 'white'
                    },
                    }}
                    startDecorator={<SearchIcon />}
                    endDecorator={<CloseRoundedIcon onClick={() => setOpen(false)} sx={{background: '#f4b55b'||'#ffba00', color: 'white', height: '100%', width: '42px', padding: 1, borderRadius: '10px', zIndex: 5, ":hover": {
                        cursor: 'pointer'
                    }, position: 'absolute', right: 0 }} />}
                    value={inputValue}
                    autoFocus
                    onChange={(e, newVal) => {
                        setInputValue(e.target.value)
                        // fetchData(e.target.value)
                    }}
                    // onBlur={() => {
                    // }}
                />
                <div class='flex flex-col gap-2 w-full items-center h-full overflow-y-scroll hide-scroll'>
                    {
                        data.map(option => {
                            console.log(option)
                            return <Box sx={{display: 'flex', padding: 1, borderRadius: '10px', margin: 1, background: '#f4b55b'|| '#0e172b', border: '1px #fef5d0 solid', ":hover": {
                                cursor: 'pointer'
                              },
                              width: '50%',
                              minWidth: '300px',
                              }} key={option.token} onClick={() => window.open('/coins/' + option.token, '_self')}>
                                <ListItemDecorator sx={{ marginRight: '10px' }}>
                                  <Avatar
                                    loading="lazy"
                                    sx={{width: '40px', height: '40px', background: 'transparent', borderRadius: 0}}
                                    width="40"
                                    src={option.icon}
                                    alt=""
                                  />
                                </ListItemDecorator>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                  <ListItemContent>
                                    <Typography style={{ color: 'black' }} fontWeight={600} level="title-sm">{option.name}</Typography>
                                    <Typography style={{ color: 'black', opacity: 0.6 }} level="body-sm">{option.token.slice(0, 4)}...{option.token.slice(-4)}</Typography>
                                  </ListItemContent>
                                  <ListItemContent>
                                  </ListItemContent>
                                </div>
                              </Box>
                        })
                    }
                </div>
            </div>
        )
    })


    return (
        <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={open}
            onClose={() => setOpen(false)}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', marginTop: 6, "& + .MuiModal-backdrop": { backdropFilter: 'none', background: 'rgb(0 0 0 / 74%)' } }}
        >
            <SearchBarInput />
        </Modal>
    );
}
