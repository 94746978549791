import { Button, Card, Chip, CssVarsProvider, Typography } from "@mui/joy";
import TopMenu from "./components/TopMenu";
import Footer from "./components/Footer";
import theme from './themes/theme.js'
import support from './assets/services/support.svg'
import updates from './assets/services/updates.svg'
import graph from './assets/services/graph.svg'
import exposure from './assets/services/exposure.svg'
import target from './assets/services/target.svg'

import ads from './assets/services/ads.svg'
import premiumAds from './assets/services/premium-ads.svg'
import spots from './assets/services/spots.svg'

import kyc from './assets/services/KYC.svg'
import audit from './assets/services/AUDIT.svg'
import kycAudit from './assets/services/KYC_AUDIT.svg'

import useWindowDimensions from "./modules/useWindowDimensions";
import { useEffect, useState } from "react";
import Disclaimer from "./components/Disclaimer";


export default function Services() {

    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);

    const { width: windowWidth, height: windowHeight } = useWindowDimensions();

    
    useEffect(() => {
        setWidth(windowWidth);
        setHeight(windowHeight);
    }, [windowWidth, windowHeight]);

    function Icon({src, height}) {
        return <img src={src} class={`mr-2`} style={{width: `${height || 22}px`}} />
    }

    return     <div class='flex flex-col items-center'>
        <Disclaimer />
    <CssVarsProvider theme={theme}>
        <TopMenu />
            <div class='container mt-8 px-4'>
                <div>
                    <Typography level='h2'>Services</Typography>
                    <div class='flex justify-between my-4' style={width < 764 ? {flexDirection: 'column', 'gap': '10px'} : {}}>
                        <Typography startDecorator={<Icon src={support} />}>Fast support within 24 hours</Typography>
                        <Typography startDecorator={<Icon src={updates} />}>Unlimited updates for your project</Typography>
                        <Typography startDecorator={<Icon src={graph} />}>Free graphs and pricing info</Typography>
                        <Typography startDecorator={<Icon src={exposure} />}>Massive exposure for your project</Typography>
                    </div>
                    <Card sx={{display: 'flex', background: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}} style={width < 764 ? {flexDirection: 'column'} : {}}>
                        <Icon src={target} height={120} />
                        <div class='flex flex-col justify-center h-full gap-1' style={width < 764 ? {alignItems: 'center'} : {}}>
                            <Typography level='h3'>List your Project</Typography>
                            <Typography level='body-sm' textAlign={width < 764 ? 'center' : 'left'}>Start by adding your project to Cado. After submission you’ll need 500 votes to be officially listed.</Typography>
                            <Typography level='h3'>FREE</Typography>
                        </div>
                        <Button color='success' sx={{marginTop: 3}} size='lg' onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLScQvIbKcue22bb9kdrvPab6bSFhPEZ070fIjHYmLiLtn8S01A/viewform?usp=sf_link')}>Submit Coin</Button>
                    </Card>
                </div>
                <div class='mt-12'>
                    <Typography level='h3' fontWeight={500} mb={2}>Online Advertising Options</Typography>
                    <div class={width >= 1280 ? 'grid grid-cols-3 gap-4' : 'flex gap-4 flex-col'}>
                    <Card style={{display: 'flex', background: 'white', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around'}}>
                        <Icon src={ads} height={120} />
                        <div class='flex flex-col justify-around h-full gap-2 items-center'>
                            <Typography level='h3'>Basic Banner Ads</Typography>
                            <Typography level='body-sm' sx={{opacity: 0.7}} textAlign="center">Your banners are shown on all pages on Cado.website, shared traffic. Two banners are required.</Typography>
                            <div class='flex gap-2'>
                                <Chip sx={{background: '#fcb900', borderRadius: '4px'}}>Desktop 728x90</Chip>
                                <Chip sx={{background: '#fcb900', borderRadius: '4px'}}>Mobile 320x100</Chip>
                            </div>
                            <Typography level='h3' mt={1}>$20 / per day</Typography>
                        </div>
                        <Button color='primary' sx={{marginTop: 1, width: '100%'}} size='lg' onClick={() => window.open('https://t.me/bebeto_catdog')}>Book Your Ads</Button>
                    </Card>
                    <Card style={{display: 'flex', background: 'white', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around'}}>
                        <Icon src={premiumAds} height={120} />
                        <div class='flex flex-col justify-around h-full gap-2 items-center'>
                            <Typography level='h3'>Premium Banner Ads</Typography>
                            <Typography level='body-sm' sx={{opacity: 0.7}} textAlign="center">The premium banner is a full width banner shown at the bottom our website. It’s a dedicated spot, meaning it doesn’t rotate with other ads.</Typography>
                            <div class='flex flex-wrap gap-2 justify-center'>
                                <Chip sx={{background: '#fcb900', borderRadius: '4px'}}>Desktop 728x90</Chip>
                                <Chip sx={{background: '#fcb900', borderRadius: '4px'}}>Tablet 1280x100</Chip>
                                <Chip sx={{background: '#fcb900', borderRadius: '4px'}}>Mobile 500x150</Chip>
                            </div>
                            <Typography level='h3' mt={1}>$30 / per day</Typography>
                        </div>
                        <Button color='primary' sx={{marginTop: 1, width: '100%'}} size='lg' onClick={() => window.open('https://t.me/bebeto_catdog')}>Book Your Ads</Button>
                    </Card>
                    <Card style={{display: 'flex', background: 'white', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around'}}>
                        <Icon src={spots} height={120} />
                        <div class='flex flex-col justify-around h-full gap-2 items-center'>
                            <Typography level='h3'>Promoted Spots</Typography>
                            <Typography level='body-sm' sx={{opacity: 0.7}} textAlign="center">Your project in the promoted coins section on top of our homepage. These are the most prominent places for projects.</Typography>
                            <Typography level='h3' mt={1}>$15 / per day</Typography>
                        </div>
                        <Button color='primary' sx={{marginTop: 1, width: '100%'}} size='lg' onClick={() => window.open('https://t.me/bebeto_catdog')}>Book Your Ads</Button>
                    </Card>
                    </div>
                </div>

                {/* BOTTOM KYC CRAP */}
                <div class='mt-12'>
                    <Typography level='h3' fontWeight={500} mb={2}>Additional Services</Typography>
                    <div class={width >= 1280 ? 'grid grid-cols-3 gap-4' : 'flex gap-4 flex-col'}>
                    <Card style={{display: 'flex', background: 'white', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around'}}>
                        <Icon src={kyc} height={120} />
                        <div class='flex flex-col justify-around h-full gap-2 items-center'>
                            <Typography level='h3'>KYC</Typography>
                            <Typography level='h3' mt={1}>$50</Typography>
                        </div>
                        <Button color='primary' sx={{marginTop: 1, width: '100%'}} size='lg' onClick={() => window.open('https://t.me/bebeto_catdog')}>Request</Button>
                    </Card>
                    <Card style={{display: 'flex', background: 'white', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around'}}>
                        <Icon src={audit} height={120} />
                        <div class='flex flex-col justify-around h-full gap-2 items-center'>
                            <Typography level='h3'>AUDIT</Typography>
                            <Typography level='h3' mt={1}>$50</Typography>
                        </div>
                        <Button color='primary' sx={{marginTop: 1, width: '100%'}} size='lg' onClick={() => window.open('https://t.me/bebeto_catdog')}>Request</Button>
                    </Card>
                    <Card style={{display: 'flex', background: 'white', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around'}}>
                        <Icon src={kycAudit} height={120} />
                        <div class='flex flex-col justify-around h-full gap-2 items-center'>
                            <Typography level='h3'>KYC + AUDIT</Typography>
                            <Typography level='h3' mt={1}>$70</Typography>
                        </div>
                        <Button color='primary' sx={{marginTop: 1, width: '100%'}} size='lg' onClick={() => window.open('https://t.me/bebeto_catdog')}>Request</Button>
                    </Card>
                    </div>
                </div>
            </div>
        <Footer />
    </CssVarsProvider>
  </div>
}