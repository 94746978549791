
import { extendTheme } from '@mui/joy/styles';

const theme = extendTheme({
  "colorSchemes": {
    "light": {
      "palette": {
        "primary": {
          "50": "#b3e0ff",
          "100": "#91d3ff",
          "200": "#6ec6ff",
          "300": "#4cbaff",
          "400": "#29adf7",
          "500": "#20a3ff",
          "600": "#1a9cff",
          "700": "#1493f5",
          "800": "#0e8aec",
          "900": "#087fe3"
        },
        "common": {},
        "neutral": {
          "50": "#f8fafc",
          "100": "#f1f5f9",
          "200": "#e2e8f0",
          "300": "#cbd5e1",
          "400": "#94a3b8",
          "500": "#64748b",
          "600": "#475569",
          "700": "#334155",
          "800": "#1e293b",
          "900": "#0f172a"
        },
        "danger": {
          "50": "#fff1f2",
          "100": "#ffe4e6",
          "200": "#fecdd3",
          "300": "#fda4af",
          "400": "#fb7185",
          "500": "#f43f5e",
          "600": "#e11d48",
          "700": "#be123c",
          "800": "#9f1239",
          "900": "#881337"
        },
        "success": {
          "50": "#fff5e0",   // Lighter shade
          "100": "#ffe9b5",  // Light shade
          "200": "#ffdc8a",  // 
          "300": "#ffcf5f",  // 
          "400": "#ffc234",  // 
          "500": "#ffba00",  // Primary color
          "600": "#e6a400",  // 
          "700": "#cc9300",  // 
          "800": "#b38100",  // 
          "900": "#996000"   // Darker shade
        },
        "warning": {
          "50": "#fffbeb",
          "100": "#fef3c7",
          "200": "#fde68a",
          "300": "#fcd34d",
          "400": "#fbbf24",
          "500": "#f59e0b",
          "600": "#d97706",
          "700": "#b45309",
          "800": "#92400e",
          "900": "#78350f"
        },
        "background": {
          "body": "var(--joy-palette-primary-700)",
          "popup": "var(--joy-palette-primary-900)"
        }
      }},
  //   "dark": {
  //     "palette": {
  //       "primary": {
          // "50": "#b3e0ff",
          // "100": "#91d3ff",
          // "200": "#6ec6ff",
          // "300": "#4cbaff",
          // "400": "#29adf7",
          // "500": "#20a3ff",
          // "600": "#1a9cff",
          // "700": "#1493f5",
          // "800": "#0e8aec",
          // "900": "#087fe3"
  //       },
  //       "common": {},
  //       "neutral": {
  //         "50": "#f8fafc",
  //         "100": "#f1f5f9",
  //         "200": "#e2e8f0",
  //         "300": "#cbd5e1",
  //         "400": "#94a3b8",
  //         "500": "#64748b",
  //         "600": "#475569",
  //         "700": "#334155",
  //         "800": "#1e293b",
  //         "900": "#0f172a"
  //       },
  //       "danger": {
  //         "50": "#fff1f2",
  //         "100": "#ffe4e6",
  //         "200": "#fecdd3",
  //         "300": "#fda4af",
  //         "400": "#fb7185",
  //         "500": "#f43f5e",
  //         "600": "#e11d48",
  //         "700": "#be123c",
  //         "800": "#9f1239",
  //         "900": "#881337"
  //       },
  //       "success": {
  //         "50": "#ffffa4",
  //         "100": "#ffff8a",
  //         "200": "#ffff6f",
  //         "300": "#ffff55",
  //         "400": "#ffff3a",
  //         "500": "#ffba00",
  //         "600": "#ffad00",
  //         "700": "#ff9f00",
  //         "800": "#ff9200",
  //         "900": "#ff8500"
  //       },
  //       "warning": {
  //         "50": "#fffbeb",
  //         "100": "#fef3c7",
  //         "200": "#fde68a",
  //         "300": "#fcd34d",
  //         "400": "#fbbf24",
  //         "500": "#f59e0b",
  //         "600": "#d97706",
  //         "700": "#b45309",
  //         "800": "#92400e",
  //         "900": "#78350f"
  //       },
  //       "background": {
  //         "body": "var(--joy-palette-primary-700)",
  //         "popup": "var(--joy-palette-primary-900)"
  //       }
  //     }
  //   }
  },
  fontFamily: {
    display: 'bayon', // applies to `h1`–`h4`
    body: 'bayon', // applies to `title-*` and `body-*`
  },
})

export default theme;