import { Button, Card, Link, Modal, Sheet, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import alert from '../assets/alert.svg'
import useWindowDimensions from "../modules/useWindowDimensions";

export default function Disclaimer() {
    
    const [open, setOpen] = useState(false)

    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);

    const { width: windowWidth, height: windowHeight } = useWindowDimensions();
    useEffect(() => {
        setWidth(windowWidth);
        setHeight(windowHeight);
    }, [windowWidth, windowHeight]);

    useEffect(() => {
        let isIn = localStorage.getItem('disclaimer')
        if (!isIn) {
            setOpen(true)
        }
    }, [])

    
    function Icon({src, height}) {
        return <img src={src} class={`mr-2`} style={{width: `${height || 22}px`}} />
    }

    return       <Modal
    open={open}
    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
  >
    <Sheet
      variant="outlined"
      sx={{
        width: '60%',
        maxWidth: '600px',
        minWidth: '343px',
        borderRadius: 'md',
        p: 3,
        boxShadow: 'lg',
        border: '2px #ffba00 solid',
        outline: 'none',
        background: '#141B27',
        boxShadow: 'none',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: width < 672 ? 2 : 3,
        paddingInline: width < 672 ? 2 : 10
      }}
    >
      <Typography
        level={width < 672 ? 'title-lg' : "h2"}
        mb={1}
      >
        Disclaimer
      </Typography>
      <Typography id="modal-desc" textAlign="center" level={width < 672 ? 'body-xs' : "body-md"}>The information on Cadok Pro is user-generated and not verified. Cadok Pro does not provide financial advice or facilitate transactions.</Typography>
      <Typography id="modal-desc" textAlign="center" level={width < 672 ? 'body-xs' : "body-md"}>Also note that the cryptocurrencies listed on this website could potentially be scams, i.e. designed to induce you to invest financial resources that may be lost forever and not be recoverable once investments are made. You are responsible for conducting your own research (DYOR) before making any investments.</Typography>
      <Card>
      <Typography id="modal-desc" sx={{color: '#ED402A'}} level={width < 672 ? 'body-xs' : "body-md"} fontWeight={600} startDecorator={<Icon src={alert} />}>WARNING: Scam Telegram Channels</Typography>
      <Typography id="modal-desc" level={width < 672 ? 'body-xs' : "body-sm"}>Our only legitimate Telegram channel for Cadok Pro is <Link href='https://t.me/catdogcommunity' color='neutral' target='_blank'>@cadokpro</Link>.</Typography>
      <Typography id="modal-desc" level={width < 672 ? 'body-xs' : "body-sm"}>Any other channel using the Cadok Pro logo and brand name is a scam!</Typography>
      </Card>
      <Button color='success' onClick={() => {
        localStorage.setItem('disclaimer', true)
        setOpen(false)
      }}>Okay, I understand</Button>
    </Sheet>
  </Modal>
}