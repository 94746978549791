import { useEffect, useState } from "react";
import useWindowDimensions from "../modules/useWindowDimensions";
import { Typography } from "@mui/joy";

function AdBanner({images, adLoading, data}) {

    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);

    const { width: windowWidth, height: windowHeight } = useWindowDimensions();

    useEffect(() => {
        setWidth(windowWidth);
        setHeight(windowHeight);
    }, [windowWidth, windowHeight]);


    function filterImagesByScreenWidth(images) {
      return images.filter(r => r.size >= width).reverse()[0] || images[0]
    }

    function ResizableImage({srcs}) {
      let filter = filterImagesByScreenWidth(srcs.image)
      let url = srcs.url
      if (filter.url == '') {
        filter = data.default.image.filter(r => r.res == filter.res)[0]
        url = data.default.url
        console.log(data.default)
      }
      return <img style={{borderRadius: '10px'}} class='hover:cursor-pointer' src={filter.url} onClick={() => window.open(url)} />
    }

    if (adLoading) {
      return <div></div>
    }

    return (
        <div style={{marginTop: '30px', display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center'}}>
            <ResizableImage srcs={images} />
            <Typography sx={{color: 'black' || '#ffba00', ":hover": {textDecoration: 'underline', cursor: 'pointer'}}} onClick={() => window.open('https://t.me/bebeto_catdog')}>Your banner here? Contact us!</Typography>
        </div>
    );
}

export default AdBanner;