import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Avatar, Button, Card, CardContent, Chip, CssVarsProvider, FormLabel, Input, TextField, Typography, extendTheme } from "@mui/joy"
import { useEffect, useState } from "react"
import theme from "./themes/theme";
import { Label } from "@mui/icons-material";
import ImageIcon from '@mui/icons-material/Image';
import LinkIcon from '@mui/icons-material/Link';
import TokenIcon from '@mui/icons-material/Token';
import { ConfigProvider, Spin, message } from "antd";
import '@fontsource/inter'

function ImageIconWithText({text}) {
    return <div class='flex gap-2'>
        <ImageIcon />
        <Chip sx={{opacity: 0.6}} fontWeight="bold">{text}</Chip>
    </div>
}

export default function AdminPanel() {

    const SERVER = 'https://catdog-api.vercel.app'
    // const SERVER = 'http://127.0.0.1:8080'

    const [lock, setLock] = useState(true)
    const [adData, setAdData] = useState({
        gifs: [{ url: '', image: '' }, { url: '', image: '' }],
        banner: { url: '', image: '' },
        default: {url: '', image: ''}
    });
    const [promotedCoins, setPromotedCoins] = useState(['', '', '', '', ''])

    const [addCoin, setAddCoin] = useState({token: '', icon: '', chain: '', name: '', description: '', scan: '', twitter: '', telegram: '', swap: '', web: ''})
    const [loading, setLoading] = useState(true)
    const [messageApi, contextHolder] = message.useMessage();

    const [allData, setAllData] = useState({})

    useEffect(() => {
        fetch(SERVER+'/api/get_ads').then(r => r.json()).then(r => {
          setAdData(r)
          fetch(SERVER+'/api/get_promoted').then(r => r.json()).then(r => {
                setPromotedCoins(r.map(r => r.token))
                fetch(SERVER+'/api/get_all').then(r => r.json()).then(r => {
                    setAllData(r)
                    setLoading(false)
                })
            })
        })
      }, [])

    const handleAdInputChange = (event) => {
        const { name, value } = event.target;
        let props = name.split(':')
        let adType = props[0]
        if (adType != 'default') {
            let index = parseInt(props[1])
            if (props[2] == 'url') {
                adData[adType][index].url = value
                setAdData(adData)
            } else if (props[2] == 'image') {
                let imageIndex = parseInt(props[3])
                adData[adType][index].image[imageIndex].url = value
                setAdData(adData)
            }
        } else {
            if (props[1] == 'url') {
                adData[adType].url = value
                setAdData(adData)
            } else if (props[1] == 'image') {
                let imageIndex = parseInt(props[2])
                adData[adType].image[imageIndex].url = value
                setAdData(adData)
            }
        }
    };

    const HandleEditChange = (e, token, ind) => {
        const { name, value } = e.target;
        allData[ind][name] = value
        setAllData(allData)
    }

    const SaveEdit = (token, ind) => {
        const d = allData[ind]
        fetch(SERVER+'/api/update_coin', {
            headers: {
                "content-type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(d)
        }).then(r => {
            messageApi.success('Saved Coin!', 2)
        })
    }

    const RemoveEdit = (token, ind) => {
        allData[ind]['removed'] = true
        fetch(SERVER+'/api/remove_coin', {
            headers: {
                "content-type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({token: token})
        }).then(r => {
            setAllData(allData) 
            messageApi.success('Removed Coin!', 2)
            setTimeout(() => {
                window.location.reload()
            }, 1000);
        })
    }

    const handlePromoteInputChange = (event) => {
        const { name, value } = event.target;
        const gifIndex = parseInt(name.split(':')[1])-1;
        promotedCoins[gifIndex] = value
        setPromotedCoins(promotedCoins)
    };

    const handleAddInputChange = (event) => {
        let { name, value } = event.target;
        if (name == 'chain') {
            value = value.toUpperCase()
        }
        if (name == 'platform') {
            value = value.toUpperCase()
            if (value == 'CMC & CG') {
                value = 'CMC_CG'
            }
            if (value == 'CG & CMC') {
                value = 'CMC_CG'
            }
        }
        addCoin[name] = value
        setAddCoin(addCoin)
    };

    function SaveAds() {
        // console.log(adData)
        fetch(SERVER+'/api/save_ads', {
            headers: {
                "content-type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(adData)
        }).then(r => {
            messageApi.success('Saved Ads!', 2)
        })
    }

    function SavePromoted() {
        fetch(SERVER+'/api/save_promoted', {
            headers: {
                "content-type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(promotedCoins)
        }).then(r => {
            messageApi.success('Saved Promoted Coins!', 2)
        })
    }

    function AddCoinToWeb() {
        fetch(SERVER+'/api/add_coin', {
            headers: {
                "content-type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(addCoin)
        }).then(r => {
            messageApi.success('Coin added!', 2)
        })
    }


    if (lock) {
        const pass = window.prompt('Enter the password:')
        if (pass == '456') {
            setLock(false)
        }
        return <div></div>
    } else {

        if (loading) {
            return <div class='flex justify-center items-center w-full h-full absolute'>
            <Spin />
        </div>
        }

        return <CssVarsProvider theme={extendTheme({fontFamily: {
            display: 'inter', // applies to `h1`–`h4`
            body: 'inter', // applies to `title-*` and `body-*`
        }})}>

            <ConfigProvider theme={{
                components: {
                    Message: {
                        zIndexPopup: 9999
                    }
                }
            }}>
                {contextHolder}
            </ConfigProvider>

            <div class='p-8 gap-4 flex flex-col'>
                <Card class='p-8'>
                    <Typography level='h3'>Advertisements</Typography>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <div class='gap-2 flex flex-col'>
                            <FormLabel style={{fontWeight: 600}}>Default</FormLabel>
                            <Input style={{ background: '#fcb900' }}
                                    label="Gif Ad 1"
                                    name="default:url"
                                    defaultValue={adData.default.url}
                                    startDecorator={<LinkIcon />}
                                    onChange={handleAdInputChange}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined" />
                            <Input style={{ background: '#fcb900' }}
                                    label="Gif Ad 1" 

                                    startDecorator={<ImageIconWithText text={adData.default.image[0].res} />}
                                    name="default:image:0" 
                                    defaultValue={adData.default.image[0].url} 

                                    onChange={handleAdInputChange} 
                                    fullWidth 
                                    margin="normal"
                                    variant="outlined" />
                            <Input style={{ background: '#fcb900' }}
                                    label="Gif Ad 1" 

                                    startDecorator={<ImageIconWithText text={adData.default.image[1].res} />}
                                    name="default:image:1" 
                                    onChange={handleAdInputChange} 
                                    defaultValue={adData.default.image[1].url} 
                                    
                                    fullWidth 
                                    margin="normal"
                                    variant="outlined" />
                            <Input style={{ background: '#fcb900' }}
                                    label="Gif Ad 1" 

                                    startDecorator={<ImageIconWithText text={adData.default.image[2].res} />}
                                    name="default:image:2" 
                                    onChange={handleAdInputChange} 
                                    defaultValue={adData.default.image[2].url} 
                                    
                                    fullWidth 
                                    margin="normal"
                                    variant="outlined" />
                            <Input style={{ background: '#fcb900' }}
                                    label="Gif Ad 1" 

                                    startDecorator={<ImageIconWithText text={adData.default.image[3].res} />}
                                    name="default:image:3" 
                                    onChange={handleAdInputChange} 
                                    defaultValue={adData.default.image[3].url} 
                                    
                                    fullWidth 
                                    margin="normal"
                                    variant="outlined" />
                            <Input style={{ background: '#fcb900' }}
                                    label="Gif Ad 1" 

                                    startDecorator={<ImageIconWithText text={adData.default.image[4].res} />}
                                    name="default:image:4" 
                                    onChange={handleAdInputChange} 
                                    defaultValue={adData.default.image[4].url} 
                                    
                                    fullWidth 
                                    margin="normal"
                                    variant="outlined" />
                        </div>
                        <div class='gap-2 flex flex-col'>
                            <FormLabel>Ad #1</FormLabel>
                            <Input style={{ background: '#fcb900' }} label="Gif Ad 1" name="small:0:url" startDecorator={<LinkIcon />} defaultValue={adData.small[0].url} onChange={handleAdInputChange} fullWidth margin="normal" variant="outlined" />
                            <Input style={{ background: '#fcb900' }} label="Gif Ad 1" startDecorator={<ImageIconWithText text={adData.small[0].image[0].res} />} name="small:0:image:0" onChange={handleAdInputChange} defaultValue={adData.small[0].image[0].url} fullWidth margin="normal" variant="outlined" />
                            <Input style={{ background: '#fcb900' }} label="Gif Ad 1" startDecorator={<ImageIconWithText text={adData.small[0].image[1].res} />} name="small:0:image:1" onChange={handleAdInputChange} defaultValue={adData.small[0].image[1].url} fullWidth margin="normal" variant="outlined" />
                        </div>
                        {/* <div class='gap-2 flex flex-col'>
                            <FormLabel>Ad #2</FormLabel>
                            <Input style={{ background: '#fcb900' }} label="Gif Ad 1" name="small:1:url" startDecorator={<LinkIcon />} defaultValue={adData.small[1].url} onChange={handleAdInputChange} fullWidth margin="normal" variant="outlined" />
                            <Input style={{ background: '#fcb900' }} label="Gif Ad 1" startDecorator={<ImageIconWithText text={adData.small[1].image[0].res} />} name="small:1:image:0" onChange={handleAdInputChange} defaultValue={adData.small[1].image[0].url} fullWidth margin="normal" variant="outlined" />
                            <Input style={{ background: '#fcb900' }} label="Gif Ad 1" startDecorator={<ImageIconWithText text={adData.small[1].image[1].res} />} name="small:1:image:1" onChange={handleAdInputChange} defaultValue={adData.small[1].image[1].url} fullWidth margin="normal" variant="outlined" />
                        </div> */}
                        <div class='gap-2 flex flex-col'>
                            <FormLabel>Premium #1</FormLabel>
                            <Input style={{ background: '#fcb900' }}
                                    label="Gif Ad 1"
                                    name="premium:0:url"
                                    defaultValue={adData.premium[0].url}
                                    startDecorator={<LinkIcon />}
                                    onChange={handleAdInputChange}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined" />
                            <Input style={{ background: '#fcb900' }}
                                    label="Gif Ad 1" 

                                    startDecorator={<ImageIconWithText text={adData.premium[0].image[0].res} />}
                                    name="premium:0:image:0" 
                                    defaultValue={adData.premium[0].image[0].url} 

                                    onChange={handleAdInputChange} 
                                    fullWidth 
                                    margin="normal"
                                    variant="outlined" />
                            <Input style={{ background: '#fcb900' }}
                                    label="Gif Ad 1" 

                                    startDecorator={<ImageIconWithText text={adData.premium[0].image[1].res} />}
                                    name="premium:0:image:1" 
                                    onChange={handleAdInputChange} 
                                    defaultValue={adData.premium[0].image[1].url} 
                                    
                                    fullWidth 
                                    margin="normal"
                                    variant="outlined" />
                            <Input style={{ background: '#fcb900' }}
                                    label="Gif Ad 1" 

                                    startDecorator={<ImageIconWithText text={adData.premium[0].image[2].res} />}
                                    name="premium:0:image:2" 
                                    onChange={handleAdInputChange} 
                                    defaultValue={adData.premium[0].image[2].url} 
                                    
                                    fullWidth 
                                    margin="normal"
                                    variant="outlined" />
                        </div>
                        <div class='gap-2 flex flex-col'>
                            <FormLabel>Premium #2</FormLabel>
                            <Input style={{ background: '#fcb900' }}
                                    label="Gif Ad 1"
                                    name="premium:1:url"
                                    defaultValue={adData.premium[1].url}
                                    startDecorator={<LinkIcon />}
                                    onChange={handleAdInputChange}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined" />
                            <Input style={{ background: '#fcb900' }}
                                    label="Gif Ad 1" 

                                    startDecorator={<ImageIconWithText text={adData.premium[1].image[0].res} />}
                                    name="premium:1:image:0" 
                                    defaultValue={adData.premium[1].image[0].url} 

                                    onChange={handleAdInputChange} 
                                    fullWidth 
                                    margin="normal"
                                    variant="outlined" />
                            <Input style={{ background: '#fcb900' }}
                                    label="Gif Ad 1" 

                                    startDecorator={<ImageIconWithText text={adData.premium[1].image[1].res} />}
                                    name="premium:1:image:1" 
                                    onChange={handleAdInputChange} 
                                    defaultValue={adData.premium[1].image[1].url} 
                                    
                                    fullWidth 
                                    margin="normal"
                                    variant="outlined" />
                            <Input style={{ background: '#fcb900' }}
                                    label="Gif Ad 1" 

                                    startDecorator={<ImageIconWithText text={adData.premium[1].image[2].res} />}
                                    name="premium:1:image:2" 
                                    onChange={handleAdInputChange} 
                                    defaultValue={adData.premium[1].image[2].url} 
                                    
                                    fullWidth 
                                    margin="normal"
                                    variant="outlined" />
                        </div>
                        <div class='gap-2 flex flex-col'>
                            <FormLabel>Premium #3</FormLabel>
                            <Input style={{ background: '#fcb900' }}
                                    label="Gif Ad 1"
                                    name="premium:1:url"
                                    defaultValue={adData.premium[2].url}
                                    startDecorator={<LinkIcon />}
                                    onChange={handleAdInputChange}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined" />
                            <Input style={{ background: '#fcb900' }}
                                    label="Gif Ad 1" 

                                    startDecorator={<ImageIconWithText text={adData.premium[2].image[0].res} />}
                                    name="premium:2:image:0" 
                                    defaultValue={adData.premium[1].image[0].url} 

                                    onChange={handleAdInputChange} 
                                    fullWidth 
                                    margin="normal"
                                    variant="outlined" />
                            <Input style={{ background: '#fcb900' }}
                                    label="Gif Ad 1" 

                                    startDecorator={<ImageIconWithText text={adData.premium[2].image[1].res} />}
                                    name="premium:2:image:1" 
                                    onChange={handleAdInputChange} 
                                    defaultValue={adData.premium[1].image[1].url} 
                                    
                                    fullWidth 
                                    margin="normal"
                                    variant="outlined" />
                            <Input style={{ background: '#fcb900' }}
                                    label="Gif Ad 1" 

                                    startDecorator={<ImageIconWithText text={adData.premium[2].image[2].res} />}
                                    name="premium:2:image:2" 
                                    onChange={handleAdInputChange} 
                                    defaultValue={adData.premium[2].image[2].url} 
                                    
                                    fullWidth 
                                    margin="normal"
                                    variant="outlined" />
                        </div>
                        {/* <div class='gap-2 flex flex-col'>
                            <FormLabel>Ad #2</FormLabel>
                            <Input style={{ background: '#fcb900' }} label="Gif Ad 2" startDecorator={<ImageIcon />} name="gif2:image" defaultValue={adData.gifs[1].image} onChange={handleAdInputChange} fullWidth margin="normal" variant="outlined" />
                            <Input style={{ background: '#fcb900' }} label="Gif Ad 2" name="gif2:url" startDecorator={<LinkIcon />} defaultValue={adData.gifs[1].url} onChange={handleAdInputChange} fullWidth margin="normal" variant="outlined" />
                        </div>
                        <div class='gap-2 flex flex-col'>
                            <FormLabel>Banner Ad</FormLabel>
                            <Input style={{ background: '#fcb900' }} label="Banner Ad" startDecorator={<ImageIcon />} defaultValue={adData.banner.image} name="banner:image" onChange={handleAdInputChange} fullWidth margin="normal" variant="outlined"
                            />
                            <Input style={{ background: '#fcb900' }} label="Banner Ad" name="banner:url" defaultValue={adData.banner.url} startDecorator={<LinkIcon />} onChange={handleAdInputChange} fullWidth margin="normal" variant="outlined"
                            />
                        </div>
                        <div class='gap-2 flex flex-col'>
                            <FormLabel>Default Ad</FormLabel>
                            <Input style={{ background: '#fcb900' }} label="Default Ad" defaultValue={adData.default.image} startDecorator={<ImageIcon />} name="default:image" onChange={handleAdInputChange} fullWidth margin="normal" variant="outlined"
                            />
                            <Input style={{ background: '#fcb900' }} label="Default Ad" name="default:url" defaultValue={adData.default.url} startDecorator={<LinkIcon />} onChange={handleAdInputChange} fullWidth margin="normal" variant="outlined"
                            />
                        </div> */}
                    </CardContent>
                    <Button color='danger' onClick={SaveAds}>Save</Button>
                </Card>

                {/* <Card class='p-8'>
                    <Typography level='h3'>Highlighted Coins</Typography>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        <FormLabel>(Add tokens of coins)</FormLabel>
                        <Input style={{ background: '#fcb900' }} startDecorator={<TokenIcon />} name="promoted:1" defaultValue={promotedCoins[0]} onChange={handlePromoteInputChange} fullWidth margin="normal" variant="outlined" />
                        <Input style={{ background: '#fcb900' }} startDecorator={<TokenIcon />} name="promoted:2" defaultValue={promotedCoins[1]} onChange={handlePromoteInputChange} fullWidth margin="normal" variant="outlined" />
                        <Input style={{ background: '#fcb900' }} startDecorator={<TokenIcon />} name="promoted:3" defaultValue={promotedCoins[2]} onChange={handlePromoteInputChange} fullWidth margin="normal" variant="outlined" />
                        <Input style={{ background: '#fcb900' }} startDecorator={<TokenIcon />} name="promoted:4" defaultValue={promotedCoins[3]} onChange={handlePromoteInputChange} fullWidth margin="normal" variant="outlined" />
                        <Input style={{ background: '#fcb900' }} startDecorator={<TokenIcon />} name="promoted:5" defaultValue={promotedCoins[4]} onChange={handlePromoteInputChange} fullWidth margin="normal" variant="outlined" />

                    </CardContent>
                    <Button color='danger' onClick={SavePromoted}>Save</Button>
                </Card> */}
                <Card class='p-8'>
                    <Typography level='h3'>Add Coin</Typography>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        <FormLabel>Name</FormLabel>
                        <Input style={{ background: '#fcb900' }} name="name" onChange={handleAddInputChange} fullWidth margin="normal" variant="outlined" />
                        <FormLabel>Description</FormLabel>
                        <Input style={{ background: '#fcb900' }} name="description" onChange={handleAddInputChange} fullWidth margin="normal" variant="outlined" />
                        <FormLabel>Token</FormLabel>
                        <Input style={{ background: '#fcb900' }} startDecorator={<TokenIcon />} name="token" onChange={handleAddInputChange} fullWidth margin="normal" variant="outlined" />
                        <FormLabel>Icon</FormLabel>
                        <Input style={{ background: '#fcb900' }} startDecorator={<ImageIcon />} name="icon" onChange={handleAddInputChange} fullWidth margin="normal" variant="outlined" />
                        <FormLabel>Chain (BSC, ETH, ARB)</FormLabel>
                        <Input style={{ background: '#fcb900' }} name="chain" onChange={handleAddInputChange} fullWidth margin="normal" variant="outlined" />
                        <FormLabel>Platform (CMC, CG, CMC & CG)</FormLabel>
                        <Input style={{ background: '#fcb900' }} name="platform" onChange={handleAddInputChange} fullWidth margin="normal" variant="outlined" />
                        <FormLabel>Chain Scan</FormLabel>
                        <Input style={{ background: '#fcb900' }} startDecorator={<LinkIcon />} name="scan" onChange={handleAddInputChange} fullWidth margin="normal" variant="outlined" />
                        <FormLabel>Website</FormLabel>
                        <Input style={{ background: '#fcb900' }} startDecorator={<LinkIcon />} name="web" onChange={handleAddInputChange} fullWidth margin="normal" variant="outlined" />
                        <FormLabel>Telegram</FormLabel>
                        <Input style={{ background: '#fcb900' }} startDecorator={<LinkIcon />} name="telegram" onChange={handleAddInputChange} fullWidth margin="normal" variant="outlined" />
                        <FormLabel>Twitter</FormLabel>
                        <Input style={{ background: '#fcb900' }} startDecorator={<LinkIcon />} name="twitter" onChange={handleAddInputChange} fullWidth margin="normal" variant="outlined" />
                        <FormLabel>Direct Swap</FormLabel>
                        <Input style={{ background: '#fcb900' }} startDecorator={<LinkIcon />} name="swap" onChange={handleAddInputChange} fullWidth margin="normal" variant="outlined" />

                    </CardContent>
                    <Button color='danger' onClick={AddCoinToWeb}>Add</Button>
                </Card>

                <Card class='p-8'>
                    <Typography level='h3'>Edit Coins</Typography>
                    <AccordionGroup sx={{ maxWidth: 400 }}>
                            {allData.map((r, i) => {
                                return <Accordion sx={{width: '100%', display: r.remove ? 'none' : 'block'}}>
                                        
                                        <AccordionSummary>
                                            <Avatar src={r.icon} />
                                            <Typography>{r.name}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <FormLabel>Platform (CMC, CG, CMC & CG)</FormLabel>
                                        <Input style={{ background: '#fcb900' }} name="platform" defaultValue={r.platform} onChange={(e) => HandleEditChange(e,r.token,i)} fullWidth margin="normal" variant="outlined" />
                                        <FormLabel>Website</FormLabel>
                                        <Input style={{ background: '#fcb900' }} startDecorator={<LinkIcon />} defaultValue={r.web} name="web" onChange={(e) => HandleEditChange(e,r.token,i)} fullWidth margin="normal" variant="outlined" />
                                        <FormLabel>Telegram</FormLabel>
                                        <Input style={{ background: '#fcb900' }} startDecorator={<LinkIcon />} defaultValue={r.telegram} name="telegram" onChange={(e) => HandleEditChange(e,r.token,i)} fullWidth margin="normal" variant="outlined" />
                                        <FormLabel>Twitter</FormLabel>
                                        <Input style={{ background: '#fcb900' }} startDecorator={<LinkIcon />} name="twitter" defaultValue={r.twitter} onChange={(e) => HandleEditChange(e,r.token,i)} fullWidth margin="normal" variant="outlined" />
                                        <div class='flex flex-col gap-4 pt-4'>
                                            <Button color='danger' onClick={() => SaveEdit(r.token,i)}>Save</Button>
                                            <Button color='danger' onClick={() => RemoveEdit(r.token,i)}>Remove</Button>
                                        </div>
                                        </AccordionDetails>
                                </Accordion>
                            })
                            }
                    </AccordionGroup>
                </Card>
            </div>
        </CssVarsProvider>
    }

}