import { AutoComplete, Autocomplete, AutocompleteOption, Avatar, Button, Image, Input, ListItemContent, ListItemDecorator } from "@mui/joy";
import { useState, useEffect } from "react";
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import qs from 'qs';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { ConfigProvider, Table, message } from 'antd';

import ReCAPTCHA from "react-google-recaptcha";
import VoteModal from "./Vote";
import useWindowDimensions from "../modules/useWindowDimensions";

const THEME = {
  "token": {
    "colorPrimary": "black",
    "colorInfo": "black",
    "colorBgBase": "white",
    "wireframe": false,
    "colorTextBase": "black"
  },
  "components": {
    "Table": {
      "colorBorderSecondary": "#fcb900",
      "colorPrimary": "black",
    }
  }
}

const getRandomuserParams = (params) => ({
  results: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

const Chains = [
  {
    name: 'ETH',
    icon: 'https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=026'
  },
  {
    name: 'BSC',
    icon: 'https://cryptologos.cc/logos/bnb-bnb-logo.svg?v=026'
  },
  {
    name: 'ARB',
    icon: 'https://cryptologos.cc/logos/arbitrum-arb-logo.svg?v=026'
  }
]

const Platforms = [
  {
    name: 'CMC',
    icon: 'https://res.cloudinary.com/dvwrpep92/image/upload/v1696084286/cmc_mnw1kn.png'
  },
  {
    name: 'CG',
    icon: 'https://res.cloudinary.com/dvwrpep92/image/upload/v1696084285/cg_xgrfxz.png'
  },
  {
    name: 'CMC_CG',
    icon: 'https://res.cloudinary.com/dvwrpep92/image/upload/v1696084283/cmc-cg_aov988.png'
  }
]

function LeaderBoard() {

  const columns = [
    {
      title: '#',
      dataIndex: 'data',
      render: ({index}) => <Typography level='title-md'>{index}</Typography>
    },
    {
      title: 'Coin',
      dataIndex: 'data',
      render: ({icon}) => <Avatar src={icon} size="lg" style={{ borderRadius: '6px', background: 'transparent' }} />,
      fixed: 'left'
    },
    {
      title: 'Name',
      dataIndex: 'data',
      render: (dd) => <Box component="div" onClick={() => window.open('/coins/' + (dd.baseToken?.address || dd.token), '_self')} sx={{ ":hover": { cursor: 'pointer' } }}>
        <Typography level='title-md'>{dd.baseToken?.name || dd.name}</Typography>
        <Typography level='body-sm'>{dd.baseToken?.symbol}</Typography>
      </Box>
    },
    {
      title: 'CMC/CG',
      dataIndex: 'data',
      render: ({platform}) => {
        if (!platform) {
          return <div></div>
        }
        return <div class='flex'>
          <Avatar src={Platforms.filter(r => r.name == platform)[0]?.icon} size='sm' style={{ height: '20px', width: '20px', marginRight: '10px' }} />
          <Typography level='title-sm'>{platform.replace("_", " & ")}</Typography>
        </div>
      }
    },
    {
      title: 'Chain',
      dataIndex: 'data',
      render: ({chain}) => <div class='flex'>
        <Avatar src={Chains.filter(r => r.name == chain)[0].icon} size='sm' style={{ height: '20px', width: '20px', marginRight: '10px' }} />
        <Typography level='title-sm'>{chain}</Typography>
      </div>
    },
    {
      title: 'Market Cap',
      dataIndex: 'data',
      render: ({fdv}) => <div class='flex'>
        <Typography level='title-sm'>$ {fdv?.toLocaleString('en-US')}</Typography>
      </div>
    },
    {
      title: 'Price',
      dataIndex: 'data',
      render: ({priceUsd}) => <div class='flex'>
        <Typography level='title-sm'>$ {priceUsd}</Typography>
      </div>
    },
    {
      title: 'Change 24h',
      dataIndex: 'data',
      render: ({priceChange}) => {
      if (!priceChange) {
        priceChange = {h24: 0}
      }
      return <div class='flex'>
        <Typography level='title-sm' style={{ color: (priceChange.h24 > 0) ? '#00ab70' : '#d8412e' }} startDecorator={(priceChange.h24 > 0) ? <ArrowDropUpIcon style={{ color: '#00ab70' }} /> : <ArrowDropDownIcon style={{ color: '#d8412e' }} />}>{priceChange.h24}%</Typography>
      </div>}
    },
    {
      title: 'Votes',
      dataIndex: 'data',
      render: ({votes}) => <div class='flex'>
        <Typography level='title-sm'>{votes}</Typography>
      </div>,
      sorter: true
    },
    {
      title: '',
      dataIndex: 'data',
      render: ({token}) => {
        return <Button color="success" disabled={localStorage.getItem(token) ? true : false} onClick={() => { setOpen(true); messageApi.info('Complete the captcha to vote.', 2); setCurrentTokenForVote(token) }}>Vote</Button>
      },
    }
  ];


  const [open, setOpen] = useState(false);
  const [currentTokenForVote, setCurrentTokenForVote] = useState();
  const [data, setData] = useState();
  const [safeChain, setSafeChain] = useState('All');
  const [safePlatform, setSafePlatform] = useState('All');
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  useEffect(() => {
    setWidth(windowWidth);
    setHeight(windowHeight);
  }, [windowWidth, windowHeight]);


  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 15,
    },
  });
  const fetchData = (chain, platform='') => {
    setLoading(true);
    fetch(`https://catdog-api.vercel.app/api/get_filtered_data?${qs.stringify(getRandomuserParams(tableParams))}&chain=` + (chain || safeChain) + '&platform=' + (platform || safePlatform))
      .then((res) => res.json())
      .then((res) => {

        setData(res.items);
        setLoading(false);

        // let paginated_data = res.items
        // let order = res.order
        // let field = res.field
        // // Convert 'paginated_data' to an array of token strings
        // const tokenStrings = paginated_data.map(x => x.token.toLowerCase());
        
        // if (paginated_data.length == 0) {
        //   setData([])
        //   setLoading(false)
        //   return
        // }

        // // Fetch data from the API
        // fetch('https://api.dexscreener.com/latest/dex/tokens/' + tokenStrings.join(','))
        //   .then(response => response.json())
        //   .then(data => {
        //     const nopairs = [];
        //     const nopairsnames = [];

        //     data.pairs.forEach(i => {
        //       const baseTokenAddress = i.baseToken.address.toLowerCase();
        //       if (tokenStrings.includes(baseTokenAddress)) {
        //         if (!nopairsnames.includes(i.baseToken.name)) {
        //           nopairsnames.push(i.baseToken.name);

        //           // Find the corresponding 'paginated_data' item
        //           const matchingItem = paginated_data.find(o => o.token.toLowerCase() === baseTokenAddress);

        //           if (matchingItem) {
        //             nopairs.push({
        //               ...i,
        //               ...matchingItem
        //             });
        //           }
        //         }
        //       }
        //     });

        //     // Sort the 'nopairs' array
        //     const isDescending = (order === 'descend');
        //     nopairs.sort((a, b) => {
        //       const aValue = a[field] || 0;
        //       const bValue = b[field] || 0;
        //       return isDescending ? bValue - aValue : aValue - bValue;
        //     });

        //     // 'nopairs' now contains the sorted and filtered data
        //     console.log(nopairs);

            // setData(nopairs);
            // setLoading(false);
        //   })
        //   .catch(error => {
        //     console.error('An error occurred:', error);
        //   });
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.totalCount,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      }).catch(r => {
        console.log('FAILED LEADERBOARD')
      })
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return (
    <div class='w-full flex flex-col items-start gap-4 my-[30px]'
    // style={width < 520 ? { paddingInline: '2rem' } : {}}
    >
      <ConfigProvider theme={{
        components: {
          Message: {
            zIndexPopup: 9999
          }
        }
      }}>
        {contextHolder}
      </ConfigProvider>
      <VoteModal open={open} setOpen={setOpen} currentTokenForVote={currentTokenForVote} messageApi={messageApi} />
      <Typography level='h2'>Browse Coins</Typography>
      <div class='flex gap-4'>
        <div>
          <FormLabel sx={{ marginBottom: '10px', opacity: 0.6, fontSize: '14px', fontWeight: 300 }}>
            Chain
          </FormLabel>
          <Select
            defaultValue="All"
            color='black'
            slotProps={{
              listbox: {
                sx: (theme) => ({
                  background: '#fcb900',
                  border: 'none',
                  // boxShadow: 'none'
                })
              }
            }}
            onChange={(e) => {
              setSafeChain(e.target.innerText)
              fetchData(e.target.innerText)
            }}
            sx={{width: '100px'}}
            >
            <Option value="All">All</Option>
            {
              Chains.map(r => <Option value={r.name}>
                <Avatar src={r.icon} size='sm' style={{ height: '20px', width: '20px', marginRight: '10px' }} />
                <Typography level='title-md'>{r.name}</Typography>
              </Option>)
            }
          </Select>
        </div>

        <div>
          <FormLabel sx={{ marginBottom: '10px', opacity: 0.6, fontSize: '14px', fontWeight: 300 }}>
            Platform
          </FormLabel>
          <Select
            defaultValue="All"
            color='black'
            onChange={(e) => {
              setSafePlatform(e.target.innerText.replace(" & ", '_'))
              fetchData(safeChain, e.target.innerText.replace(" & ", '_'))
            }}
            slotProps={{
              listbox: {
                sx: (theme) => ({
                  background: '#fcb900',
                  border: 'none',
                  // boxShadow: 'none'
                })
              }
            }}
            sx={{width: '140px'}}
            >
            <Option value="All">All</Option>
            {
              Platforms.map(r => <Option value={r.name}>
                <Avatar src={r.icon} size='sm' style={{ height: '20px', width: '20px', marginRight: '10px' }} />
                <Typography level='title-md'>{r.name.replace('_' , ' & ')}</Typography>
              </Option>)
            }
          </Select>
        </div>
      </div>
      <ConfigProvider theme={THEME}>

        <Table
          columns={columns}
          rowKey={(record) => record.data.index}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={loading}
          onChange={handleTableChange}
          style={{ width: '100%', borderRadius: '50px' }}
        />
      </ConfigProvider>
    </div>
  );
}

export default LeaderBoard;