import { Avatar, Box, Button, Card, Chip, CssVarsProvider, Divider, Typography } from "@mui/joy";
import TopMenu from "./components/TopMenu";
import Ads from "./components/Ads";
import theme from "./themes/theme";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { ConfigProvider, Spin, message } from "antd";
import VoteModal from "./components/Vote";
import { useEffect, useState } from "react";
import BuySells from "./components/BuySells";
import Footer from './components/Footer';
import useWindowDimensions from './modules/useWindowDimensions'
import { useParams } from 'react-router';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AdBanner from "./components/AdBanner";
import Disclaimer from "./components/Disclaimer";

const Chains = [
    {
        name: 'ETH',
        icon: 'https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=026'
    },
    {
        name: 'BSC',
        icon: 'https://cryptologos.cc/logos/bnb-bnb-logo.svg?v=026'
    },
    {
        name: 'ARB',
        icon: 'https://cryptologos.cc/logos/arbitrum-arb-logo.svg?v=026'
    }
]

const Platforms = [
    {
      name: 'CMC',
      icon: 'https://res.cloudinary.com/dvwrpep92/image/upload/v1696084286/cmc_mnw1kn.png'
    },
    {
      name: 'CG',
      icon: 'https://res.cloudinary.com/dvwrpep92/image/upload/v1696084285/cg_xgrfxz.png'
    },
    {
      name: 'CMC_CG',
      icon: 'https://res.cloudinary.com/dvwrpep92/image/upload/v1696084283/cmc-cg_aov988.png'
    }
  ]

function Icon({src, href}) {
    return <img class='mr-2 hover:cursor-pointer' onClick={() => {
        window.open(href)
    }} src={src} style={{width: `${30}px`, background: '#fcb900', padding: '6px', borderRadius: '6px'}} />
}

function Coins() {

    const params = useParams()

    const [messageApi, contextHolder] = message.useMessage();
    const [open, setOpen] = useState(false);
    const [currentTokenForVote, setCurrentTokenForVote] = useState();
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)

    const [adData, setAdData] = useState({})
    const [adLoading, setAdLoading] = useState(true)

    useEffect(() => {
        fetch('https://catdog-api.vercel.app/api/get_ads').then(r => r.json()).then(r => {
          setAdData(r)
          setAdLoading(false)
        })
    }, [])
    

    useEffect(() => {
        fetch('https://catdog-api.vercel.app/api/get_token?token=' + params.token).then(r => r.json()).then(r => {
            setData(r)
            setLoading(false)
        })
    }, [])

    const token = params.token


    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);

    const { width: windowWidth, height: windowHeight } = useWindowDimensions();

    useEffect(() => {
        setWidth(windowWidth);
        setHeight(windowHeight);
    }, [windowWidth, windowHeight]);

    if (loading) {
        return <div class='flex justify-center items-center w-full h-full absolute'>
            <Spin />
        </div>
    }

    return <div class='flex flex-col items-center'>
        <Disclaimer />
        <CssVarsProvider theme={theme}>
            <ConfigProvider theme={{
                components: {
                    Message: {
                        zIndexPopup: 9999
                    }
                }
            }}>
                {contextHolder}
            </ConfigProvider>
            <VoteModal open={open} setOpen={setOpen} currentTokenForVote={data.token} messageApi={messageApi} />
            <TopMenu />
            <div class='container flex items-center flex-col'>
                <div class='px-4'>
                    <Ads data={adData} setData={setAdData} adLoading={adLoading} />
                </div>
                <div class='px-4 flex gap-4 flex-col w-full'>
                    <div class='flex gap-4 flex-col' style={width < 870 ? { width: '100%' } : {}}>
                        <Card sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', background: 'white', border: 'none' }} style={width < 768 ? { flexDirection: 'column', alignItems: 'center' } : {}}>
                            <div class='flex'>
                                <Avatar src={data.icon} size='lg' sx={{borderRadius: '10px', background: 'transparent'}} style={width < 768 ? {height: '60px', width: '60px'} : { height: '120px', width: '120px' }} />
                                <div class='ml-2 gap-2 flex flex-col'>
                                    <div class='flex gap-2 items-center'>
                                        <Typography level='h4'>{data.baseToken.name}</Typography>
                                        <Chip size='lg' color='success' style={{fontWeight: 600, color: 'white', borderRadius: '6px', background: '#fcb900'}}>{data.baseToken.symbol}</Chip>
                                        {
                                            data.platform ? 
                                        <Avatar src={Platforms.filter(r => data.platform == r.name)[0].icon} />
                                        : ''}
                                    </div>
                                    <Box sx={{":hover": { cursor: 'pointer' }}} onClick={() => { navigator.clipboard.writeText(data.token); messageApi.info('Text copied to clipboard', 0.5) }}>
                                        <Chip style={{background: '#fcb900', borderRadius: '6px', padding: '4px', paddingInline: '8px'}}
                                        startDecorator={<img src={Chains.filter(r => r.name == data.chain)[0].icon} class='h-6 mr-2' />}
                                        endDecorator={<ContentCopyRoundedIcon sx={{ width: '30px',color: 'white' }} />}
                                        >
                                            {data.token.slice(0, 4)}...{data.token.slice(-4)}
                                        </Chip>
                                    </Box>
                                    <div class='flex justify-start gap-2'>
                                        <Icon src='https://res.cloudinary.com/dvwrpep92/image/upload/v1695644484/telegram_zzxn1u.svg' href={data.telegram}/>
                                        <Icon src='https://res.cloudinary.com/dvwrpep92/image/upload/v1695644701/twitter-white_n2t2hj.svg' href={data.twitter}/>
                                        <Icon src='https://res.cloudinary.com/dvwrpep92/image/upload/v1696160718/scan_qnd1zl.svg' href={data.scan}/>
                                        <Icon src='https://res.cloudinary.com/dvwrpep92/image/upload/v1696443793/website_yefvnh.svg' href={data.web}/>
                                    </div>
                                </div>
                            </div>
                            <div class='flex flex-col gap-2 items-end' style={width < 768 ? { alignItems: 'center' } : {}}>
                                <Typography level='body-lg' sx={{opacity: 0.5}}>{data.baseToken.name} Price (USD):</Typography>
                                <div class='flex'>
                                    <Typography level='h4'>$ {data.priceUsd}</Typography>
                                    <Typography style={{color: data.priceChange.h24 < 0 ? 'rgb(216, 65, 46)' : 'rgb(0, 171, 112)'}} startDecorator={(data.priceChange.h24 > 0) ? <ArrowDropUpIcon style={{ color: 'rgb(0, 171, 112)', marginRight: '-5px', marginLeft: '5px' }} /> : <ArrowDropDownIcon style={{ color: 'rgb(216, 65, 46)', marginRight: '-5px', marginLeft: '5px' }} />}>{data.priceChange.h24}%</Typography>
                                </div>
                                <Button size='sm' startDecorator={<img src="https://res.cloudinary.com/dvwrpep92/image/upload/v1696161230/bunny-mono_imh2xi.png" style={{height: '22px'}} />} onClick={() => window.open(data.swap)}>PancakeSwap</Button>
                            </div>
                        </Card>
                    </div>
                    <Card sx={{ display: 'flex', background: 'white', justifyContent: 'space-between', width: '100%', flexDirection: 'row', border: 'none', padding: 1, paddingInline: 2 }}>
                            <div class='flex flex-col items-center'>
                                <Typography sx={{ opacity: 0.4 }} level='title-xs'>Total Votes</Typography>
                                <Typography level='title-lg'>{data.votes}</Typography>
                            </div>
                            <Button color="success" disabled={localStorage.getItem(data.token) ? true : false} onClick={() => { setOpen(true); messageApi.info('Complete the captcha to vote.', 2); setCurrentTokenForVote(data.token) }}>Vote for {data.baseToken.symbol}</Button>
                    </Card>
                    <Card sx={{background: 'white', border: 'none'}}>
                        <Typography level='title-lg'>Description</Typography>
                        <Typography level='body-sm' style={{opacity: 0.7}}>{data.description}</Typography>
                    </Card>
                    <Card sx={{background: 'white', border: 'none'}}>
                        <Typography level='title-lg'>Market Data</Typography>
                        <div class='flex justify-between' style={width < 768 ? {flexDirection: 'column', gap: '20px'} : {}}>
                            <div style={width < 768 ? {display: 'flex', justifyContent: 'space-between'} : {}}>
                                <Typography level='body-md' style={{opacity: 0.5}}>Price</Typography>
                                <Typography level='title-lg'>$ {data.priceUsd.toLocaleString('en-US')}</Typography>
                            </div>
                            <div style={width < 768 ? {display: 'flex', justifyContent: 'space-between'} : {}}>
                                <Typography level='body-md' style={{opacity: 0.5}}>Price Change (24h)</Typography>
                                <Typography level='title-lg' style={{color: data.priceChange.h24 < 0 ? 'rgb(216, 65, 46)' : 'rgb(0, 171, 112)'}} startDecorator={(data.priceChange.h24 > 0) ? <ArrowDropUpIcon style={{ color: 'rgb(0, 171, 112)', marginRight: '-5px' }} /> : <ArrowDropDownIcon style={{ color: 'rgb(216, 65, 46)', marginRight: '-5px', marginLeft: '5px' }} />}>{data.priceChange.h24}%</Typography>
                            </div>
                            <div style={width < 768 ? {display: 'flex', justifyContent: 'space-between'} : {}}>
                                <Typography level='body-md' style={{opacity: 0.5}}>Marketcap</Typography>
                                <Typography level='title-lg'>$ {data.fdv.toLocaleString('en-US')}</Typography>
                            </div>
                            <div style={width < 768 ? {display: 'flex', justifyContent: 'space-between'} : {}}>
                                <Typography level='body-md' style={{opacity: 0.5}}>Total Liquidity</Typography>
                                <Typography level='title-lg'>$ {data.liquidity.usd.toLocaleString('en-US')}</Typography>
                            </div>
                        </div>
                    </Card>
                    <iframe class='w-full h-[800px]' src={data.url + '?embed=1&info=0&theme=dark'} />
                    {/* <Card style={width < 870 ? { width: '100%' } : { width: '40%' }}>
                        <Typography level='title-lg'>{data.baseToken.name}</Typography>
                        <Typography level='body-sm'>{data.description}</Typography>
                        <Card sx={{ display: 'flex', background: '#38445c', justifyContent: 'space-between', width: '100%', flexDirection: 'row', border: '0px', padding: 1, paddingInline: 2 }}>
                            <div>
                                <Typography sx={{ opacity: 0.4 }} level='title-xs'>Votes</Typography>
                                <Typography level='title-lg'>{data.votes}</Typography>
                            </div>
                            <Button color="success" disabled={localStorage.getItem(data.token) ? true : false} onClick={() => { setOpen(true); messageApi.info('Complete the captcha to vote.', 2); setCurrentTokenForVote(data.token) }}>Vote</Button>
                        </Card>
                        <BuySells data={data} />
                    </Card> */}
                </div>
                <div class='px-4'>
                    <AdBanner images={adData.premium ? adData.premium[1] : []} data={adData} adLoading={adLoading} />
                </div>
            </div>
        </CssVarsProvider>
        <Footer />
    </div>
}

export default Coins;