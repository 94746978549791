import * as React from 'react';
import Box from '@mui/joy/Box';
import Drawer from '@mui/joy/Drawer';
import ButtonGroup from '@mui/joy/ButtonGroup';
import Button from '@mui/joy/Button';
import List from '@mui/joy/List';
import Divider from '@mui/joy/Divider';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import { Typography } from '@mui/joy';
import AdIcon from '../assets/adIcon.svg'
import SubmitIcon from '../assets/submit.svg'
import ContactIcon from '../assets/contact.svg'

function Ad() {
  return <img src={AdIcon} style={{opacity: '0.5'}} />
}

function Submit() {
  return <img src={SubmitIcon} style={{opacity: '0.5'}}/>
}

function Contact() {
  return <img src={ContactIcon} style={{opacity: '0.5'}} />
}

export default function MenuDrawer({ state, setState }) {

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <React.Fragment>
      {['top', 'right', 'bottom', 'left'].map((anchor) => (
        <Drawer
          key={anchor}
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
          size='md'
        >
          <Box sx={{ padding: 4, background: '#fcb900', height: '100%' }}>
            <Typography level='body-sm' sx={{color: 'black', fontWeight: 700}} mb={1}>Cadok</Typography>
            <Typography onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLScQvIbKcue22bb9kdrvPab6bSFhPEZ070fIjHYmLiLtn8S01A/viewform?usp=sf_link')} startDecorator={<Submit />} level='title-md' mb={2} sx={{
              ":hover": {
                textDecoration: 'underline',
                cursor: 'pointer'
              }
            }}>Submit coin</Typography>
            <Typography onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSc_3tI98DCJJW-qVufTfqIHN-ZhHm6STVJS7cTmC6DTv10FLA/viewform?usp=sf_link')} startDecorator={<Submit />} level='title-md' mb={2} sx={{
              ":hover": {
                textDecoration: 'underline',
                cursor: 'pointer'
              }
            }}>Update Coin</Typography>
            <Typography onClick={() => window.open('https://t.me/cadokpro')} startDecorator={<Contact />} level='title-md' mb={2} sx={{
              ":hover": {
                textDecoration: 'underline',
                cursor: 'pointer'
              }
            }}>Contact Us</Typography>

            <Typography level='body-sm' mb={1} sx={{color: 'black', fontWeight: 700}}>Services</Typography>
            <Typography onClick={() => window.open('/services', '_self')} startDecorator={<Ad />} level='title-md' sx={{
              ":hover": {
                textDecoration: 'underline',
                cursor: 'pointer'
              }
            }}>Advertising</Typography>
            <div class='flex gap-2 flex-col items-start mt-6'>
              <div class='flex items-center' onClick={() => window.open('https://twitter.com/catdog_coin')}>
                <Button variant='soft' sx={{ height: '42px', width: '42px', padding: 0, borderRadius: '50px', background: '#00C2F2', ":hover": { background: '#00C2F2' }, zIndex: 5 }}>
                  <img src='https://res.cloudinary.com/dvwrpep92/image/upload/v1695644701/twitter-white_n2t2hj.svg' />
                </Button>
                <Typography level='body-xs' style={{ whiteSpace: 'nowrap' }} sx={{
                  ":hover": {
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }, background: '#fef5d0' || '#222D41', padding: 1, paddingLeft: 2, paddingInline: 3, borderTopRightRadius: '10px', borderBottomRightRadius: '10px', left: '-10px', position: 'relative', zIndex: 2
                }}>Follow us on twitter!</Typography>
              </div>
              <div class='flex items-center' onClick={() => window.open('https://t.me/catdogbnb')}>
                <Button variant='soft' sx={{ height: '42px', width: '42px', padding: 0, borderRadius: '50px', background: '#01A3FE', ":hover": { background: '#00C2F2' }, zIndex: 5 }}>
                  <img src='https://res.cloudinary.com/dvwrpep92/image/upload/v1695644484/telegram_zzxn1u.svg' />
                </Button>
                <Typography level='body-xs' style={{ whiteSpace: 'nowrap' }} sx={{
                  ":hover": {
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }, background: '#fef5d0' || '#222D41', padding: 1, paddingLeft: 2, paddingInline: 3, borderTopRightRadius: '10px', borderBottomRightRadius: '10px', left: '-10px', position: 'relative', zIndex: 2
                }}>Join our telegram group!</Typography>
              </div>
            </div>
          </Box>
        </Drawer>
      ))}
    </React.Fragment>
  );
}