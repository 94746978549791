import { AutoComplete, Autocomplete, AutocompleteOption, Box, Button, Image, Input, Link, ListItemContent, ListItemDecorator, TabList, Tabs, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
// import SearchIcon from '@mui/icons-material/Search';
import useWindowDimensions from "../modules/useWindowDimensions";
import MenuIconSvg from '../assets/menu.svg'
import SearchBarModal from "./SearchBar";

import SearchIconSvg from '../assets/search.svg'
import MenuDrawer from "./MenuDrawer";
import Tab, { tabClasses } from '@mui/joy/Tab';
import { Dropdown } from "antd";

function SearchIcon() {
  return <img src={SearchIconSvg} style={{opacity: 0.2}} />
}

function MenuIcon() {
  return <img src={MenuIconSvg} />
}

function UseLess() {
  const [index, setIndex] = useState(0);
  return (
      <Tabs
        aria-label="Pipeline"
        value={index}
        onChange={(event, value) => setIndex(value)}
        style={{background: 'transparent', width: '100%'}}
      >
        <TabList
          sx={{
            pt: 2,
            justifyContent: 'center',
            [`&& .${tabClasses.root}`]: {
              flex: 'initial',
              bgcolor: 'transparent',
              fontSize: '20px',
              color: 'white',
              width: '100%',
              '&:hover': {
                bgcolor: 'transparent',
                colorr: 'white'
              },
              [`&.${tabClasses.selected}`]: {
                '&::after': {
                  height: '4px',
                  borderTopLeftRadius: '3px',
                  borderTopRightRadius: '3px',
                  bgcolor: '#f4b55b',
                },
              },
            },
          }}
        >
          <Tab indicatorInset>
            Best Coins Today
          </Tab>
          <Tab indicatorInset>
            All Time Best
          </Tab>
          <Tab indicatorInset>New Listings</Tab>
        </TabList>
      </Tabs>
  );
}

function TopMenu({disableBottomTabs}) {

  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [open, setOpen] = useState(false);
  // const [data, setData] = useState(0)
  // const [loading, setLoading] = useState(true)

  // useEffect(() => {
  //   fetch('https://catdog-api.vercel.app/api/get_hots').then(r => r.json()).then(r => {
  //     setData(r)
  //     setLoading(false)
  //   })
  // }, [])

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  useEffect(() => {
    setWidth(windowWidth);
    setHeight(windowHeight);
  }, [windowWidth, windowHeight]);

  // const [q, setQ] = useState('')

  function SearchBarShowcase() {
    const [hover, setHover] = useState(false)
    return <div class='relative'>
      <Autocomplete
        startDecorator={<SearchIcon />}
        placeholder="Search coins"
        size='lg'
        style={{
          userSelect: 'none',
          pointerEvents: 'none'
        }}
        sx={{
          width: '100%',
          background: '#333333'||'#1F2634',
          boxShadow: 'none',
          borderRadius: '10px',
          "& input::placeholder": {
            fontWeight: 300,
            opacity: 0.2,
            color: 'white'
          },
          borderColor: hover ? '#f4b55b' : 'transparent'
        }}
      />
      <Box component="div" class='absolute h-full w-full transparent top-0' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => {
        setOpen(true)
      }}></Box>
    </div>

  }

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  return (
    <div class='w-full bg-[#141B27] bg-[#1B1B1B] flex flex-col items-center mb-4'>
      {/* {
        !loading ? 
      <div class='h-full w-full bg-[#0F141E] flex justify-center' style={{borderBottom: '1px #232C3E solid'}}>
        <div class='container w-full flex justify-start items-center py-1 gap-10 overflow-x-scroll'>
            <div class='flex gap-2 pl-3'>
              <Typography sx={{whiteSpace: 'nowrap'}} level='body-xs'>BNB Price:</Typography>
              <Typography level='body-xs' sx={{color: '#ffba00', whiteSpace: 'nowrap'}}>${data.bnb}</Typography>
            </div>
            <div class='flex gap-2'>
              <Typography sx={{whiteSpace: 'nowrap'}} level='body-xs'>Projects Listed:</Typography>
              <Typography level='body-xs' sx={{color: '#ffba00', whiteSpace: 'nowrap'}}>{data.listed}</Typography>
            </div>
            <div class='flex gap-2 pr-3'>
              <Typography sx={{whiteSpace: 'nowrap'}} level='body-xs'>Total Votes:</Typography>
              <Typography level='body-xs' sx={{color: '#ffba00', whiteSpace: 'nowrap'}}>{data.votes}</Typography>
            </div>
        </div>
      </div>
      : ''} */}
      <div class='container h-24 w-full flex justify-between items-center px-6'>
        <SearchBarModal open={open} setOpen={setOpen} />
        <MenuDrawer state={state} setState={setState} />
        <div class='h-full flex items-center gap-4'>
          <img src='https://res.cloudinary.com/dvwrpep92/image/upload/v1696169019/60315ecf-1d9c-45f7-9cde-225a3f9f32a0_ppjesl.png' onClick={() => window.open('/', '_self')} class='hover:cursor-pointer h-14' />
        </div>
        {
          width > 770 ? <SearchBarShowcase /> : ''
        }
        <div class='flex gap-6'>
          {
            (width > 1280) ?
            <div class='flex items-center'>
              <Dropdown menu={{items: [
                {key: '1', label: <Link href='/services'>Advertise</Link>}
              ]}} placement="bottomRight" arrow>
                <Button variant="plain" sx={{color: 'white', ":hover": {color: 'white', background: 'transparent'}}}>
                  <Typography style={{color: 'white'}} level='body-lg'>Services</Typography>
                </Button>
              </Dropdown>
              <Dropdown menu={{items: [
                {key: '1', label: <Link target='_blank' href='https://docs.google.com/forms/d/e/1FAIpQLSc_3tI98DCJJW-qVufTfqIHN-ZhHm6STVJS7cTmC6DTv10FLA/viewform?usp=sf_link'>Update Coin</Link>},
                {key: '2', label: <Link target='_blank' href='https://docs.google.com/forms/d/e/1FAIpQLScQvIbKcue22bb9kdrvPab6bSFhPEZ070fIjHYmLiLtn8S01A/viewform?usp=sf_link'>Submit Coin</Link>},
                {key: '3', label: <Link target='_blank' href='https://t.me/cadokpro'>Contact Us</Link>}
              ]}} placement="bottomRight" arrow>
                <Button variant="plain" sx={{color: 'white', ":hover": {color: 'white', background: 'transparent'}}}>
                  <Typography style={{color: 'white'}} level='body-lg'>Listing</Typography>
                </Button>
              </Dropdown>
            </div>
         : '' }
        {
            width > 1024 ?
              <div class='flex gap-2'>
                <Button variant='solid' color='primary' sx={{ height: '42px', width: '42px', borderRadius: '10px', padding: 0 }} onClick={() => window.open('https://twitter.com/catdog_coin')}>
                  <img src='https://res.cloudinary.com/dvwrpep92/image/upload/v1695644701/twitter-white_n2t2hj.svg' />
                </Button>
                <Button variant='solid' color='primary' sx={{ height: '42px', width: '42px', borderRadius: '10px', padding: 0 }} onClick={() => window.open('https://t.me/+vgG0LHjya8JhN2Nh')}>
                  <img src='https://res.cloudinary.com/dvwrpep92/image/upload/v1695644484/telegram_zzxn1u.svg' />
                </Button>
              </div> : ''
          }
          {
            width > 770 ?
              <Button color='success'
                size="lg" style={{ display: width < 700 ? 'none' : 'block' }} onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLScQvIbKcue22bb9kdrvPab6bSFhPEZ070fIjHYmLiLtn8S01A/viewform?usp=sf_link')}>Submit Coin</Button> : ''
          }
          {
            width <= 770 ? <Button variant="outlined" sx={{
              padding: 0, border: 'none', ":hover": {
                background: 'none'
              }
            }} onClick={() => setOpen(true)}>
              <img src={SearchIconSvg} style={{ height: '28px' }} />
            </Button> : ''
          }
          {
            (width < 1280 && !disableBottomTabs) ? 
          <Button variant="outlined" sx={{
            padding: 0, border: 'none', ":hover": {
              background: 'none'
            }
          }} onClick={() => {
            setState({ ...state, right: true })
          }}><MenuIcon /></Button> : ''}
        </div>
      </div>
      {/* {
        (width > 1280 && !disableBottomTabs) ?
      <div class='container px-80'>
        <UseLess />
      </div>
      : '' } */}
    </div>
  );
}

export default TopMenu;