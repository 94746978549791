import logo from './logo.svg';
import './App.css';
import TopMenu from './components/TopMenu';
import theme from './themes/theme.js'
// import '@fontsource/poppins';
import '@fontsource/bayon'
import { Button, CssVarsProvider, Divider, Typography } from '@mui/joy';
import Ads from './components/Ads';
import Shots from './components/shots';
import LeaderBoard from './components/LeaderBoard';
import Footer from './components/Footer';
import { useEffect, useState } from 'react';
import AdBanner from './components/AdBanner';
import Notices from './components/Notices';
import Disclaimer from './components/Disclaimer';

function App() {

  const [adData, setAdData] = useState({})
  const [adLoading, setAdLoading] = useState(true)

  useEffect(() => {
    fetch('https://catdog-api.vercel.app/api/get_ads').then(r => r.json()).then(r => {
      setAdData(r)
      setAdLoading(false)
    })
  }, [])

  return (
    <div className="App" class='flex flex-col items-center'>
      <CssVarsProvider theme={theme}>
          <Disclaimer />
          <TopMenu />
          <div class='container flex justify-center flex-col px-6 w-full'>
            <Ads data={adData} setData={setAdData} adLoading={adLoading} />
            <Shots />
            <AdBanner data={adData} images={adData.premium ? adData.premium[1] : []} adLoading={adLoading} />
            <LeaderBoard />
            <AdBanner data={adData} images={adData.premium ? adData.premium[2] : []} adLoading={adLoading} />
          </div>
          <Notices />
          <Footer />
      </CssVarsProvider>
    </div>
  );
}

export default App;
